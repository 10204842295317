


















































































































import { Vue, Component } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";

import {
  MetaModule as metaStore,
  UserRegistrationModule as userStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
  },
})
export default class UserRegistrationClass extends Vue {
  palikas: any[] = [];
  qUsers: any[] = [];
  userList: any[] = [];
  districtName: any = [];
  filter = {
    name: "",
    district: "",
    palika: "",
  };

  get AdminRouter() {
    return AdminRouter;
  }

  get routePath() {
    return AdminRouter;
  }
  get districts() {
    return metaStore.districtList;
  }
  async created() {
    metaStore.loadDis();
    await userStore.loadAllVehicleUsers();
    this.userList = userStore.userList;
  }

  edit(id: any) {
    this.$router.push(AdminRouter.UserRegister + "?id=" + id);
  }

  filterUsers() {
    var filterData = userStore.userList;

    if (this.filter.name !== "") {
      this.filter.name = this.filter.name.toLowerCase();
      filterData = filterData.filter((x: any) =>
        x.username.toLowerCase().includes(this.filter.name)
      );
    }

    this.userList = filterData;
  }
  clearFilter() {
    this.filter = {
      name: "",
      district: "",
      palika: "",
    };
    this.userList = userStore.userList;
  }
  submit(e: any) {
    userStore.createUser();
  }
}
